.titulo-modal {
    color: #fff;
    
}

.conteudo-modal {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 780px) {
    .titulo-modal {
        margin-top: -45px;
        text-align: center;
        width: 80%;
    }
}
