/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar Css ************/
@media only screen and (min-width: 780px) {
  #menuMobile {
    background-color: none;
  }
}

@media only screen and (max-width: 780px) {
  #menuMobile {
    background-color: rgba(0, 0, 0, 0.95);
  }
}

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}


nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

@media only screen and (max-width: 780px) {
  nav.navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.95);
  }

  nav.navbar {
    padding: 0 0 0 0;
  }
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;

  @media only screen and (max-width: 780px) {
    nav.navbar .navbar-nav .nav-link.navbar-link {
      opacity: 1;
      /* text-align: left; */
    }
  }
}

@media only screen and (max-width: 780px) {
  nav.navbar .navbar-nav .nav-link.navbar-link {
    width: 100%;
    line-height: 60px;
    text-align: center;
    /* border-bottom: 1px solid #fff; */
    border-top: 1px solid #fff;

  }
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
  
}

@media only screen and (max-width: 780px) {
  span.navbar-text {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    gap: 30px;
    /* border-top: 1px solid #fff; */
  }
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

@media only screen and (max-width: 780px) {
  .social-icon {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 780px) {
  .social-icon a {
    width: 70px;
    height: 70px;
    background: rgba(0, 0, 0, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.8);
    display: flex;

  }
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

@media only screen and (max-width: 780px) {
  .navbar-text button {
    border: 1px solid rgba(255, 255, 255, 0.8);
    width: 100%;
    margin: 0;
    padding: 100% auto;
  }

}

.navbar-text button span {
  z-index: 1;
  width: 100%;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.select-lingua {
  display: flex;
  justify-content: center;
  margin-left: 2.5%;
}

@media only screen and (max-width: 780px) {
  .select-lingua {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    margin: 0;

  }

  .button-hash {
    width: 100%;
  }

}

@media only screen and (min-width: 780px) {
  #contatos-title {
    display: none;
  }
}



@media only screen and (max-width: 780px) {
  nav.navbar {
    color: red;
    z-index: 9999;
  }
  
  .button-hash {
    display: none;
  }
  
  .navbar .navbar-expand-md .navbar-light {
    background-color: red;
  }
  #navId {
    background-color: #fff;
  }
}

/************ Banner Css ************/

.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}


.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}

@media only screen and (max-width: 780px) {
  .banner {
    padding: 130px 0 0 0;
  }

  .banner h1 {
    padding: 30px 0;
  }

  .banner p {
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    text-align: center;
    /* padding: 0 23px; */
  }

  .banner img {
    display: none;
  }

  .banner button {
    padding: 0 10px 100px 0;
  }
}

/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}

.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.skill h2 {
  font-size: 45px;
  font-weight: 700;
}

.skill p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}


.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.single-chart {
  /* width: 33%; */
  display: flex;
  margin-left: 3.8vw;
  justify-content: center;
  display: flex;
  width: 170px;

}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}


.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.orange .circle {
  stroke: #AA367C;
}

.percentage {
  fill: #fff;
  font-family: centra, sans-serif;
  font-size: 0.35em;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  text-anchor: middle;
  cursor: default;
  transition: all .1s ease-in-out;
}

.percentage:hover {
  font-size: 0.45em;
}


.percentage-text {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 780px) {
  .subtitle-skill {
    padding-bottom: 20px;
  }
}


@media only screen and (max-width: 780px) {
  .single-chart {
    /* margin: 0 50px; */
    width: 90%;
  }

  .skill p {
    margin: 20px 0;
    padding: 0 23px;
    font-size: 16px;
    text-align: center;
  }

  .skill-slider {
    width: 80%;
  }

  .skill-bx {
    padding: 0 0 0 0;
    background: rgba(21, 21, 21, 0.45);
  }

  .skill-bx h2 {
    padding: 20px 0 10px 0;
  }

  .react-multiple-carousel__arrow {
    /* display: none; */
    margin: 0 -7px;
  }
}


/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  /* background-color: black; */
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
  decoration: none;
}

.project a {
  text-decoration: none;
}


.project-icon {
  width: 40px;
  height: auto;
  margin-left: 20px;
}



.project span {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
  decoration: none;
}

.personal-project {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 780px) {
  .project a {
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
  }

  .project span {
    margin-top: 15px;
  }

  .project-icon {
    display: flex;
    margin-top: 15px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
  }

}


.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  cursor: pointer;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

@media only screen and (max-width: 780px) {
  .project p {
    width: 100%;
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    text-align: center;
  }

  #pills-tab {
    width: 100%;
    height: auto;
  }

  .nav-item {
    display: 100%;
  }

  .project .nav.nav-pills {
    width: 100%;
    margin: 0 auto;
    border-radius: 0;
    background-color: rgb(255 255 255 / 10%);
    /* overflow: hidden; */
  }

  .project .nav.nav-pills .nav-link::before {
    background: transparent;

  }

  .nav-link#projects-tabs-tab-first {
    border: 1px solid #C4C4CC;
    border-radius: 0;
  }

  .nav-link#projects-tabs-tab-first:hover {
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  }

  .nav-link#projects-tabs-tab-second {
    border: 1px solid #C4C4CC;
    border-radius: 0;
  }

  .nav-link#projects-tabs-tab-second:hover {
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  }

  .nav-link#projects-tabs-tab-third {
    border: 1px solid #C4C4CC;
    border-radius: 0;
    height: 80px;
  }

  .nav-link#projects-tabs-tab-third:hover {
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  }
}

/************ Modal Css ************/

.botao-modal {
  color: #C4C4CC;
  border: 1px solid #C4C4CC;
  padding: 5px 10px;
  margin-left: 95%;
  transition: all .5s ease;
}

.botao-modal:hover {
  color: #000;
  border: 1px solid #000;
  background-color: #fff;
  border: 1px solid #fff;
}

.conteudo-modal {
  display: flex;
  flex-direction: column;
}

.titulo-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-content {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
}

.project-image {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}


.project-image img {
  width: 50%;
}

.project-info {
  width: 50%;
  border-left: 2px solid #C4C4CC;
  padding-left: 40px;
}

.tech-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.col li {
  margin-top: 10px
}

.mymodal {
  position: fixed;
  margin-top: 20px;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  transform: translate(-50%, -50%);

  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  z-index: 9999;
  opacity: 1;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.links {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.link-projeto {
  color: #fff;
}

@media only screen and (max-width: 780px) {
  .botao-modal {
    color: #C4C4CC;
    border: 1px solid #C4C4CC;
    margin-top: 20%;
    z-index: 9999;
    margin-left: 85%;
    transition: all .5s ease;
    padding: 10px 20px;
  }

  .project-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

  }

  .project-image img {
    width: 100%;
    height: auto;
    margin-top: 1vh;
  }

  .project-info {
    margin-top: 5vh;
    border: none;
    width: 100%;
    padding-left: 0;
    text-align: center;
  }

  .tech-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0;
  }

  .mymodal {
    width: 100%;
    height: 100%;
    z-index: inherit;
  }
}

/************ contact Css ************/
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}

.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}

@media only screen and (max-width: 780px) {
  .contact h2 {
    margin-top: 3vh;
    text-align: center;
  }

  .contact form button {
    width: 100%;
    margin-top: 0;
    border-radius: 50px;
  }

  .contact form button::before {
    border-radius: 50px;
  }
}

@media only screen and (max-width: 780px) {
  .contact h2 {
    text-align: center;
    margin-top: 4vh;
  }
  .contact form button {
    width: 100%;
    border-radius: 50px;
    margin-top: 0;
  }
  .contact form button::before {
    border-radius: 50px;
  }

}


/************ Portifolio Css ************/

.portifolio {
  display: flex;
  justify-content: center;
}

.portifolio button {
  font-weight: 700;
  color: #000;
  /* background-image: linear-gradient(90deg, #AA367C, #4A2FBD); */
  background-color: #fff;
  padding: 14px 78px;
  border: 2px #000 solid;
  font-size: 18px;
  margin-top: 25px;
  /* border-radius: 50px; */
  position: relative;
  transition: 0.3s ease-in-out;

}

.portifolio button span {
  z-index: 1;
  position: relative;
}

.portifolio button:hover {
  color: #fff;
  border: 2px #fff solid;
  /* border-radius: 50px; */
}

.portifolio button::before {
  content: "";
  background-image: linear-gradient(90deg, #4A2FBD, #AA367C);
  background-color: black;
  /* border-radius: 50px; */
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.9s ease-in-out;

}

.portifolio button:hover::before {
  width: 100%;
}

@media only screen and (max-width: 780px) {
  .portifolio button {
    width: 100%;
    /* padding: 2vh 33vw; */
  }
  .portifolio button span {
    width: 100%;
    
  }
}


/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}

.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}

.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}

.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}

.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}

.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

.footer img {
  width: 26%;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 10px;
}

.logo {
  width: 200px;
  height: auto;
}

@media only screen and (max-width: 780px) {
  .newsletter-bx h3 {
    text-align: center;
  }
@media only screen and (max-width: 780px) {
  .newsletter-bx {
    padding: 40px 40px;
  }
  .newsletter-bx h3 {
    text-align: center;
  }
  .footer img {
    width: 100%;
    align-item: center; 
  }
  .social-icon a {
    width: 70px;
    height: 70px;
  }
}

  .newsletter-bx {
    width: 100%;
  }
}