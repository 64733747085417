@font-face {
	font-family: Centra;
	src: url('../font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('../font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('../font/CentraNo2-Book.ttf');
	font-weight: 400;
}


.perfil {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    /* background: black; */
}

.title {
    font-family: 'Centra', sans-serif !important;
    color: #fff;
    margin-bottom: 5px;
}

.subtitle1 {
    margin-bottom: 15px;
    margin-top: 15px;
    color: #7C7C8A;
}

.content{
    /* margin: 0px 300px; */
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
}



#imagemPerfil {
    margin: 45px 0;
    width: 190px;
    height: 190px;
    border-top-left-radius: 50% 50%; 
    border-top-right-radius: 50% 50%; 
    border-bottom-right-radius: 50% 50%; 
    border-bottom-left-radius: 50% 50%;
}


.subtitle {
    text-align: center;
    font-family: 'Centra', sans-serif !important;
    color: #C4C4CC;
}

#history {
    display: flex;
    flex-direction: row;
    margin-top: 5vh;
    justify-content: space-between;
    word-wrap: break-word;
}

@media only screen and (max-width: 780px) {
    #history {
        display: flex;
        flex-direction: column;
    }
}

.education {
    align-items: flex-start;
    width: 50%;
}

@media only screen and (max-width: 780px) {
    .education {
        align-items: center;
        width: 100%;
    }
}

.cardContent {
    /* width: 80%; */
    border-left: #C4C4CC 2px solid;
    padding-left: 20px;
    padding-top: 25px;
}

.textContent {
    color: #C4C4CC!important;
}

.read-more-less--more,
.read-more-less--less {
    color: #C4C4CC;
    margin-left: 10px;
    border: 1px #7C7C8A solid;
    padding: 2px 15px;
}

/* @media only screen and (max-width: 780px) {
    
.read-more-less--more,
.read-more-less--less {
    padding: 20px 25px;
    margin-top: 35px;
}

} */

.read-more-less--more:hover,
.read-more-less--less:hover {
    color: #7C7C8A;
    background-color: #fff;
    border: 1px #fff solid;
    transition: all 0.5s;
}


.personalSkills {
    justify-content: center;
    width: 50%;
    margin-bottom: 50px;
}

@media only screen and (max-width: 780px) {
    .personalSkills {
        width: 100%;
        margin-top: 5vh;
    }
    .historyTitle {
        font-size: 30px;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;
    }
}


.rodape {
    background-image: url('../img/footer-bg.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.rodape img {
    width: 26%;
  }

.rodape p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    margin-top: 20px;
}
